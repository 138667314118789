import * as style from '../styles/advantage.module.scss';
import React from 'react';
import classnames from 'classnames';

export enum PositionEnum {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ColorsEnum {
  GREEN = '#5e8c79',
  ORANGE = '#e29c60',
}

interface Props {
  header: string;
  color?: ColorsEnum;
  position?: PositionEnum;
  children?: string;
  visible?: boolean;
  image?: string;
  backgroundSize?: string;
}

export default function Advantage({
  image = '/',
  backgroundSize = 'cover',
  header,
  color = ColorsEnum.GREEN,
  position = PositionEnum.LEFT,
  children,
  visible = true,
}: Props) {
  if (!header || !children) {
    return <div>Chybí jeden z parametrů: nadpis (header), perex.</div>;
  }

  return (
    <section
      style={{ display: visible ? 'flex' : 'none' }}
      className={classnames(style.advantage, style[position])}
    >
      <div
        className={style.image}
        style={{ backgroundImage: 'url(' + image + ')', backgroundSize: backgroundSize }}
      ></div>
      <div className={style.description}>
        <h1 style={{ color: color }}>{header}</h1>
        <p>{children}</p>
      </div>
    </section>
  );
}
``;

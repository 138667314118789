// extracted by mini-css-extract-plugin
export var StarsKeyFrames = "demoImagesLeader-module--StarsKeyFrames--OmB81";
export var bfp = "demoImagesLeader-module--bfp--36OO+";
export var companyHinting = "demoImagesLeader-module--companyHinting--c10o5";
export var demoImagesLeader = "demoImagesLeader-module--demoImagesLeader--d5Exm";
export var filePicker = "demoImagesLeader-module--filePicker--fDAj+";
export var opportunity = "demoImagesLeader-module--opportunity--dWVjs";
export var orders = "demoImagesLeader-module--orders--gJL0Z";
export var stars = "demoImagesLeader-module--stars--eW+Z2";
export var stars1 = "demoImagesLeader-module--stars1--owvBs";
export var stars2 = "demoImagesLeader-module--stars2--Xq6Tm";
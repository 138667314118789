import LayoutLeaderPage from '../components/layouts/LeaderPage';
import * as style from '../styles/pages/index.module.scss';
import * as partnersStyles from '../styles/partnersLogos.module.scss';
import SimpleAdvantageBar from '../components/SimpleAdvantageBar';
import Advantage, { PositionEnum } from '../components/Advantage';
import Testimonial from '../components/Testimonial';
import React, { useState } from 'react';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

import { graphql } from 'gatsby';
import { homepageData } from '../data/homepage.data';
import classnames from 'classnames';

function Index() {
  const [visibleAllProducts, toogleMoreProducts] = useState(false);
  const { homepageCz, partnersLogos } = homepageData();
  const data = homepageCz;
  return (
    <div>
      <Helmet title="TALXIS Automatizujte každodenní procesy"> </Helmet>
      <div className={style.backgroundTriangles}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <LayoutLeaderPage>
        <section className={style.intro}>
          <div className={style.center}>
            <h1>{parse(`${data.slogan}`)}</h1>
            <div className={style.logoCenter}>
              <img
                src="/assets/logo/talxis_black.png"
                alt="Talxis logo"
                className={style.logoCenter_image}
              />
              <img
                src="/assets/logo/tm.svg"
                className={style.logoCenter_trademark}
                alt="Trademark"
              />
            </div>
          </div>
        </section>

        <section className={style.simpleAdvatageBars}>
          <div className={style.center}>
            {data.hlavniVyhody?.split(';').map((advantage: any) => (
              <SimpleAdvantageBar>{advantage}</SimpleAdvantageBar>
            ))}
          </div>
        </section>

        <section className={style.bigText}>
          <div className={style.center}>
            <h1>{parse(`${data.blokHeader}`)}</h1>
            <p>{parse(`${data.blokText}`)}</p>
          </div>
        </section>

        <section className={style.advantages}>
          <div className={style.center}>
            <h1>{parse(`${data.hlavniVyhodyNadpis}`)}</h1>
            <div className={style.advantagesList}>
              {data?.homepageFeatures?.map((feature: any, key: number) => (
                <Advantage
                  visible={key < 2 ? true : visibleAllProducts ? true : false}
                  key={key}
                  position={key % 2 ? PositionEnum.RIGHT : PositionEnum.LEFT}
                  backgroundSize={feature.bgSize}
                  image={feature.image.mediaItemUrl}
                  header={feature.title}
                >
                  {feature.description}
                </Advantage>
              ))}
            </div>
            {!visibleAllProducts ? (
              <button
                className={style.buttonShowMoreAdvantges}
                onClick={() => {
                  // eslint-disable-next-line no-undef
                  toogleMoreProducts(visibleAllProducts ? false : true);
                  window.scrollTo(0, window.pageYOffset - 750);
                }}
              >
                {' '}
                Zobrazit další výhody
              </button>
            ) : (
              ''
            )}
          </div>
        </section>

        <section className={style.testimonials}>
          <div className={style.center}>
            <Testimonial></Testimonial>
          </div>
        </section>

        <div className={classnames(style.center, partnersStyles.partnersList)}>
          <h1>Obchodní partneři a systémoví integrátoři</h1>
          <ul className={partnersStyles.partnersLogos}>
            {partnersLogos?.logos?.map((img: any, i: number) => (
              <li key={i}>
                <img src={img.sourceUrl} alt={img.altText} />
              </li>
            ))}
          </ul>
        </div>
      </LayoutLeaderPage>
    </div>
  );
}
export default Index;

export const query = graphql`
  query ($language: String!) {
    wpObecnPEklady(jazyk: { eq: $language }) {
      id
      kontaktujteNasFooter
      mapawebovychstranek
      vyzkouetZdarma
    }
  }
`;

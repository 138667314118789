import { useStaticQuery, graphql } from 'gatsby';
export const useHomepageMetaDataEn = () => {
  const site = useStaticQuery(graphql`
    query HomepageQueryEn {
      wpPage(id: { eq: "cG9zdDo5ODE=" }) {
        homepageCz {
          blokHeader
          blokText
          fieldGroupName
          hlavniText
          hlavniVyhody
          slogan
          tags
          hlavniVyhodyNadpis
          homepageFeatures {
            description
            fieldGroupName
            title
            image {
              mediaItemUrl
            }
          }
        }
      }
    }
  `);
  return site.wpPage.homepageCz;
};

import * as style from '../styles/testimonial.module.scss';
import React from 'react';
// Core modules imports are same as usual
import { Pagination, Autoplay } from 'swiper';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss'; // core Swiper
import 'swiper/scss/navigation'; // Navigation module
import 'swiper/scss/pagination'; // Pagination module
import { testimonialsData } from '../data/testimonials.data';

export default function Testimonial() {
  const { nodes } = testimonialsData();

  let items = [];

  for (const [key, testimonial] of nodes.entries()) {
    let picture = testimonial.logo.mediaItemUrl;
    items.push(
      <SwiperSlide key={key}>
        <div className={style.swiper}>
          <div className={style.text}>
            <img src="/assets/icons/talxis.svg" alt="Talxis icon" />
            <p>{testimonial.text}</p>
            <div className={style.author}>
              <span>{testimonial.title}</span>
              <span>{testimonial.pozice}</span>
            </div>
          </div>
          <div className={style.image} style={{ background: `url(${picture})` }}></div>
        </div>
      </SwiperSlide>,
    );
  }

  return (
    <div className={style.testimonial}>
      <Swiper
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 8000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
      >
        {items}
      </Swiper>
    </div>
  );
}

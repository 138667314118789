import Head from '../Head';
import SocialIcons from '../SocialIcons';
import Footer from '../Footer';
import * as style from '../../styles/layouts/leaderPage.module.scss';
import * as generalStyle from '../../styles/styles.module.scss';
import React from 'react';
import classnames from 'classnames';
import parse from 'html-react-parser';
import { homepageData } from '../../data/homepage.data';
import DemoImagesLayout from '../shared/DemoImagesLayout';
interface Props {
  children: React.ReactNode;
}

export default function LayoutLeaderPage({ children }: Props) {
  const { homepageCz } = homepageData();
  return (
    <section>
      {/* <LastUpdate /> */}
      <section className={style.carousel}>
        <div className={classnames(generalStyle.center, style.zindex)}>
          <Head bigLogo={true} color="purple" fixed={true}></Head>

          <Head bigLogo={true} color="purple"></Head>

          <div className={style.whyUs}>
            <div className={style.mainText}>
              <div className={style.textWhyUs}></div>
              <div className={style.textHeader}>{parse(`${homepageCz?.hlavniText}`)}</div>
              <div className={style.textKeywords}>
                {homepageCz?.tags?.split(';').map((tags: any) => (
                  <span>{tags}</span>
                ))}
              </div>
            </div>
          </div>

          <div className={style.socialIcons}>
            <SocialIcons />
          </div>

          <div className={style.continueDown}></div>
        </div>

        <div className={style.image}>
          {/* <Buldozer></Buldozer> */}
          <DemoImagesLayout></DemoImagesLayout>
        </div>
      </section>

      <div className={style.mainContent}>{children}</div>

      <section className={style.footerSection}>
        <Footer></Footer>
      </section>
    </section>
  );
}

import * as style from '../styles/smallAdvatage.module.scss';
import React from 'react';

export default function SimpleAdvantageBar(props: any) {
  return (
    <section className={style.smallAdvatage}>
      <img src="/assets/icons/talxis.svg" alt="Talxis icon" />
      <p> {props.children} </p>
    </section>
  );
}

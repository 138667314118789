import { useStaticQuery, graphql } from 'gatsby';

//TODO TESTMINIALS NEJSOU FILTROVANÝ PODLE JAZYKU
export const useTestimonialsMetadataEn = () => {
  const data = useStaticQuery(graphql`
    query TestimonialEn {
      allWpTestimonials: allWpTestimonials {
        nodes {
          id
          pozice
          title
          text
          logo {
            altText
            mediaItemUrl
          }
        }
      }
    }
  `);
  return data?.allWpTestimonials;
};

import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useHomepageMetaDataCs } from '../hooks/cs/homepage-metadata.cs';
import { useHomepageMetaDataEn } from '../hooks/en/homepage-metadata.en';

export function homepageData(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'en') {
    data = useHomepageMetaDataEn();
  } else if (language == 'cs') {
    data = useHomepageMetaDataCs();
  }
  return data;
}

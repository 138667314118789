import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useTestimonialsMetadataCs } from '../hooks/cs/testimonials-metadata';
import { useTestimonialsMetadataEn } from '../hooks/en/testimonials-metadata.en';

export function testimonialsData(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = useTestimonialsMetadataCs();
  } else {
    data = useTestimonialsMetadataEn();
  }
  return data;
}

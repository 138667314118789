import { useStaticQuery, graphql } from 'gatsby';
export const useHomepageMetaDataCs = () => {
  const site = useStaticQuery(graphql`
    query HomepageQueryCs {
      wpPage(id: { eq: "cG9zdDo2MQ==" }) {
        partnersLogos {
          logos {
            altText
            sourceUrl
            title
            description
          }
        }
        homepageCz {
          blokHeader
          blokText
          fieldGroupName
          hlavniText
          hlavniVyhody
          slogan
          tags
          hlavniVyhodyNadpis
          homepageFeatures {
            description
            title
            image {
              mediaItemUrl
            }
          }
        }
      }
    }
  `);
  return site.wpPage;
};

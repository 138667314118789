import * as style from '../../styles/shared/demoImagesLeader.module.scss';
import React from 'react';

export default function DemoImagesLayout() {
  return (
    <section className={style.demoImagesLeader}>
      <div className={style.stars2}></div>
      <div className={style.stars1}></div>
      <div className={style.opportunity}></div>
      <div className={style.companyHinting}></div>
      <div className={style.bfp}></div>
      <div className={style.filePicker}></div>
      <div className={style.orders}></div>
    </section>
  );
}

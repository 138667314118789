// extracted by mini-css-extract-plugin
export var advantages = "index-module--advantages--mvO0F";
export var advantagesList = "index-module--advantagesList--D2+HL";
export var backgroundTriangles = "index-module--backgroundTriangles--JcfbY";
export var bigText = "index-module--bigText--43lZT";
export var buttonShowMoreAdvantges = "index-module--buttonShowMoreAdvantges--DVlnh";
export var center = "index-module--center--nlRss";
export var intro = "index-module--intro--M+mgv";
export var logoCenter = "index-module--logoCenter--oQXKF";
export var logoCenter_image = "index-module--logoCenter_image--AljVb";
export var logoCenter_trademark = "index-module--logoCenter_trademark--Yr1FA";
export var simpleAdvatageBars = "index-module--simpleAdvatageBars--UHaPZ";
export var testimonials = "index-module--testimonials--vzG1B";